<template>
  <div>
    <div class="page-banner" v-if="!hasUpdatableSubscription">
      <StripeIntervalToggler />
    </div>
    <div class="page-content">
      <div class="row">
        <div class="col-md-12">
          <ContentHeader :title="$gettext('Software')" />
        </div>
      </div>
      <div class="row">
        <Product
          v-for="(product, index) in productsByType(productType.software)"
          :key="index"
          :product="product"
        />
      </div>

      <div class="row pt-4">
        <div class="col-md-12">
          <ContentHeader
            :title="$gettext('Our Network (Jobs Network)')"
            class="mb-4"
          >
            <template #description>
              <div>
                {{
                  $gettext(
                    "All Jobs Network products include our Partners Network for free"
                  )
                }}
              </div>
            </template>
          </ContentHeader>
        </div>
      </div>
      <div class="row">
        <Product
          v-for="(product, index) in productsByType(productType.our_network)"
          :key="index"
          :product="product"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Product from "@/components/Cards/Product";
import { productType } from "@/enums/products";
import { mapActions, mapGetters } from "vuex";
import ContentHeader from "@/components/ContentHeader";
import StripeIntervalToggler from "@/views/Boutique/components/StripeIntervalToggler";

export default {
  components: { StripeIntervalToggler, ContentHeader, Product },
  data: () => ({
    productType
  }),
  created() {
    Promise.all([this.fetchAll(), this.fetchSubscription()]).finally(
      this.stopFetching
    );
  },
  computed: {
    ...mapGetters("products", ["productsByType"]),
    ...mapGetters("subscriptions", ["hasUpdatableSubscription"])
  },
  methods: {
    ...mapActions("products", ["fetchAll"]),
    ...mapActions("app", ["stopFetching"]),
    ...mapActions("subscriptions", { fetchSubscription: "fetch" })
  }
};
</script>
