<template>
  <div class="col-md-6 col-xl-4 mb-4">
    <div class="product-card card eq">
      <div class="card-header d-flex">
        <div class="card-image mr-3 mt-1">
          <JobboardIcon :name="product.icon" />
        </div>
        <div>
          <h5 class="card-title my-0">{{ product.title[lg] }}</h5>
          <div v-if="showBadge" class="badge float-right" :class="badgeColor">
            {{ badgeText }}
          </div>
          <p class="card-category">
            {{ product.display_pricing[getStripeInterval][lg] }}
            <span v-if="getStripeInterval === 'year'"
              ><translate> *Billed annually</translate></span
            >
          </p>
        </div>
      </div>
      <div class="card-body mb-0">
        <div>
          <div>
            {{ product.description[lg] }}
          </div>
          <div v-if="product.type === 'our_network'" class="text-muted mt-2">
            <u><translate>Partners Network included :</translate></u>
            <PartnerNetworkIncludedIcons class="mt-2" />
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="text-right">
          <a v-if="product.url" :href="product.url[lg]" target="_blank"
            ><translate>Learn more</translate></a
          >
          <span v-if="showButton" class="ml-4">
            <button
              v-show="showAddToCart"
              @click.prevent="addProductToCart(product)"
              class="btn btn-info"
            >
              <translate>Add to cart</translate>
            </button>
            <button
              v-show="!showAddToCart"
              @click.prevent="removeProduct(product.id)"
              class="btn btn-danger"
            >
              <translate>Remove from cart</translate>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import JobboardIcon from "@/components/JobboardIcon";
import PartnerNetworkIncludedIcons from "@/components/PartnerNetworkIncludedIcons";
import { mapGetters, mapMutations } from "vuex";
import { productType } from "@/enums/products";
export default {
  components: { JobboardIcon, PartnerNetworkIncludedIcons },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    jobboardTypes: [productType.our_network, productType.partner_network]
  }),
  computed: {
    ...mapGetters("me", ["lg"]),
    ...mapGetters("cart", ["hasProduct", "products", "getStripeInterval"]),
    showButton() {
      return !this.product.in_subscription;
    },
    showAddToCart() {
      return !this.hasProduct(this.product.id);
    },
    showBadge() {
      return this.badgeText;
    },
    badgeText() {
      return this.product.in_subscription ? this.$gettext("Subscribed") : null;
    },
    badgeColor() {
      return [
        this.product.is_bundled
          ? "badge-jbsmd-light"
          : this.product.in_subscription
          ? "badge-primary"
          : null
      ];
    }
  },
  methods: {
    ...mapMutations("cart", ["addProduct", "removeProduct", "setRedirectPath"]),
    addProductToCart(product) {
      if (!this.hasProduct(product.id)) {
        this.addProduct(product);
        this.setRedirectPath(null);
        this.$toast.open(this.$gettext("Product added to the cart."));
      }
    }
  }
};
</script>

<style scoped>
/* Make card equal height*/
.eq {
  height: 100%;
  width: 100%;
}
</style>
