<template>
  <div class="stripe-interval-toggler container-fluid">
    <div class="row">
      <div class="col-md-12 text-center">
        <b-form-group>
          <b-form-radio-group v-model="stripeInterval" buttons>
            <b-form-radio
              value="month"
              class="mx-1"
              :class="[stripeInterval === 'month' ? 'btn-jbsmd' : '']"
            >
              <translate>Monthly</translate>
            </b-form-radio>
            <b-form-radio
              value="year"
              class="mx-1"
              :class="[stripeInterval === 'year' ? 'btn-jbsmd' : '']"
            >
              <translate>Annually</translate>
              <span class="badge badge-jbsmd-light">-17%</span>
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("cart", ["getStripeInterval"]),
    stripeInterval: {
      get() {
        return this.getStripeInterval;
      },
      set(value) {
        return this.setStripeInterval(value);
      }
    }
  },
  methods: {
    ...mapMutations("cart", ["setStripeInterval"])
  }
};
</script>
